import React from 'react';
import PropTypes from 'prop-types';
import './GiftDetails.scss';
import { has, isEmpty, isObject } from 'underscore';

export const GiftDetails = ({ giftPreviewData = null }) => {

  function getField(fieldName) {
    if (hasPreviewData() && has(giftPreviewData, fieldName) && !isEmpty(giftPreviewData[fieldName])) {
      return giftPreviewData[fieldName];
    }

    return 'N/A';
  }

  function hasPreviewData() {
    return isObject(giftPreviewData) && !isEmpty(giftPreviewData);
  }

  return (
    hasPreviewData() && (
      <div className="gift-details mt-1 mt-md-8">
        <p className="details-row mb-2 mb-md-4">
          <span className="pr-2 pr-md-3">From:</span>
          <span className="position-relative font-weight-bold details-item">{ getField('senderName') }</span>
        </p>

        <p className="details-row mb-2 mb-md-4">
          <span className="pr-2 pr-md-3">Recipient:</span>
          <span className="position-relative font-weight-bold details-item">{ getField('recipientName') }</span>
        </p>

        <p className="details-row mb-2 mb-md-4">
          <span className="pr-2 pr-md-3">Message:</span>
          <span className="position-relative font-weight-bold details-item">{ getField('message') }</span>
        </p>
      </div>
    )
  );
};

GiftDetails.propTypes = {
  giftPreviewData: PropTypes.object,
};

export default GiftDetails;
