import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

export const SuccessGiftModal = ({ isOpen = false, onConfirm = () => {}, onClose = () => {} }) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header>
        <h4 className={`h2 mb-0`}>Thank You!</h4>
        <button type="button" className="close px-2" aria-label="Close" onClick={onClose}>
          <span className="uil uil-times" aria-hidden="true" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className={`mb-0`}>You've successfully sent a gift. An email with details will be delivered in a couple of minutes.</div>
      </Modal.Body>
      <Modal.Footer>
        <div className={`d-flex`}>
          <button type="button" className="btn-tg-sale" onClick={onConfirm}>Ok</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

SuccessGiftModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default SuccessGiftModal;
