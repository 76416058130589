import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isArray, has, extend, isObject } from 'underscore';
import './SendGiftForm.scss';
import { Form } from 'react-bootstrap';
import { EMAIL_PATTERN } from '../../../constants/validation.const';
import Select from 'react-select';

export const SendGiftForm = ({
  isPreviewMode,
  giftItemsList,
  type,
  giftId,
  data,
  isDisabled,
  onGiftChange = (e) => {},
  onChange = (e) => {},
}) => {
  const [selectedGift, setSelectedGift] = useState(null);

  useEffect(() => {
    if (!isArray(giftItemsList) || isEmpty(giftItemsList)) {
      return;
    }

    if (selectedGift) {
      return;
    }

    const option = getPreselectedOption();

    onSelectChange(option);
  }, [giftId, giftItemsList]);

  function getPreselectedOption() {
    const options = getGiftOptions();

    if (!isArray(options) || isEmpty(options)) {
      return null;
    }

    if (!giftId) {
      return options[0];
    }

    return (options || []).find(item => item.value === giftId) || options[0];
  }

  function getGiftOptionsLabel() {
    return type === 'package' ? 'Package' : 'Membership Plan';
  }

  function getGiftOptions() {
    if (!['package', 'membership'].includes(type) || !isArray(giftItemsList) || isEmpty(giftItemsList)) {
      return [];
    }

    return (giftItemsList || []).map(item => ({ value: item.id, label: item.title }));
  }

  function updateEmail(target, propertyName) {
    const email = target.value;
    const isValid = EMAIL_PATTERN.test(email);

    if (!isValid) {
      target.setCustomValidity('An element\'s value does not match its pattern attribute.');
    } else {
      target.setCustomValidity('');
    }

    updateField(email, propertyName);
  }

  function updateField(value, propertyName) {
    if (!has(data, propertyName)) {
      return;
    }

    onChange(extend({}, data, {
      [propertyName]: value
    }));
  }

  function onSelectChange(option) {
    setSelectedGift(option);

    if (isObject(option) && has(option, 'value')) {
      onGiftChange(option.value);
    }
  }

  return (
    <>
      <Form.Group className={`form-group required`}>
        <Form.Label className="label">{ getGiftOptionsLabel() }</Form.Label>
        <Select
          value={selectedGift}
          onChange={onSelectChange}
          options={getGiftOptions()}
          className={`select-gift-container`}
          classNamePrefix={`select-gift`}
          isSearchable={true}
          disabled={isDisabled}
          required={true}
        />
        <Form.Control.Feedback type="invalid">
          Please, provide a valid name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`form-group required`}>
        <Form.Label className="label">Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your name"
          required
          id="name"
          minLength="1"
          maxLength="255"
          value={data.senderName}
          onChange={(event) => updateField(event.target.value, 'senderName')}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid">
          Please, provide a valid name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`form-group required`}>
        <Form.Label className="label">Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email"
          required
          id="email"
          minLength="1"
          maxLength="255"
          autoComplete="email"
          value={data.senderEmail}
          onChange={(event) => updateEmail(event.target, 'senderEmail')}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid">
          Please, provide a valid email.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`form-group required`}>
        <Form.Label className="label">Recipient's Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter recipient's name"
          required
          id="recipient-name"
          minLength="1"
          maxLength="255"
          value={data.recipientName}
          onChange={(event) => updateField(event.target.value, 'recipientName')}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid">
          Please, provide a valid recipient's name.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`form-group required`}>
        <Form.Label className="label">Recipient's Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter recipient's email"
          required
          id="recipient-email"
          minLength="1"
          maxLength="255"
          value={data.recipientEmail}
          onChange={(event) => updateEmail(event.target, 'recipientEmail')}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid">
          Please, provide a valid recipient's email.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className={`form-group`}>
        <Form.Label className="label">Your message</Form.Label>
        <Form.Control
          as="textarea"
          rows="3"
          placeholder="Type your message here..."
          id="message"
          maxLength="2000"
          value={data.message}
          onChange={(event) => updateField(event.target.value, 'message')}
          disabled={isDisabled}
        />
      </Form.Group>

      <Form.Group className={`form-group required mb-0`}>
        <Form.Check type="checkbox" id="terms" className="pl-0">
          <Form.Check.Input
            checked={data.termsState}
            onChange={(event) => updateField(event.target.checked, 'termsState')}
            disabled={isDisabled}
            required
          />

          <Form.Check.Label className="checkbox">
            <span className="marker marker-inverse"/>
            <span className={`description text-dark text-normal mr-2`}>
                I’ve read and agree with <a href="/terms" target="_blank">Term of Use</a> and <a href="/privacy" target="_blank" className="text-nowrap">Privacy Policy</a>
              </span>
          </Form.Check.Label>

          <Form.Control.Feedback type="invalid">
            You must accept the Term of Use and Privacy Policy
          </Form.Control.Feedback>
        </Form.Check>
      </Form.Group>
    </>
  );
};

SendGiftForm.propTypes = {
  isPreviewMode: PropTypes.bool.isRequired,
  giftItemsList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  giftId: PropTypes.number,
  onGiftChange: PropTypes.func,
  onChange: PropTypes.func,
};

export default SendGiftForm;
