import React, { useEffect, useState } from 'react';
import './SendGiftTab.scss';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import SendGiftForm from '../SendGiftForm/SendGiftForm';
import GiftPreview from '../GiftPreview/GiftPreview';
import GiftDetails from '../GiftDetails/GiftDetails';
import Loader from '../../Common/Loader/Loader';
import axiosService from '../../../services/axios.service';
import { extend, has, isEmpty, isObject } from 'underscore';
import { loadStripe } from '@stripe/stripe-js/pure';

export const SendGiftTab = ({
  giftItems,
  activeTab,
  type,
  giftId,
  isReady = false,
  isPreviewMode = false,
  data = {},
  onDataChange = (e) => {},
}) => {
  const stripePromise = loadStripe(process.env.STRIPE_KEY);

  const [isLocked, setIsLocked] = useState(true);
  const [giftItem, setGiftItem] = useState(null);
  const [packageItemsCache, setPackageItemsCache] = useState([]);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (isReady) {
      setIsLocked(false);
    }
  }, [isReady]);

  function onGiftChange(id) {
    if (!id) {
      return;
    }

    switch (type) {
      case 'package': return updatePackageDetails(id);
      case 'membership': return updatePlanDetails(id);
    }
  }

  function updateData(data) {
    onDataChange(data);
  }

  function updatePackageDetails(id) {
    const cachedPackage = packageItemsCache.find(pack => pack.id === id);

    if (isObject(cachedPackage) && !isEmpty(cachedPackage)) {
      setGiftItem(cachedPackage);
    } else {
      loadPackage(id);
    }
  }

  function updatePlanDetails(id) {
    const giftItemDetails = (giftItems || []).find(item => item.id === id);

    setGiftItem(giftItemDetails);
  }

  function loadPackage(id) {
    setIsLocked(true);

    getPackage(id)
      .then(data => {
        setPackageItemsCache([...packageItemsCache, data]);
        setGiftItem(data);
      })
      .finally(() => setIsLocked(false));
  }

  async function getPackage(id) {
    return await axiosService
      .get(`${process.env.API_URL}/packages/${id}`)
      .then((res) => res.data.data);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity()) {
      await processCheckout();
    }
  }

  function processCheckout() {
    const isMembership = activeTab === 'membership';
    const giftType = isMembership ? 'plan' : 'package';
    const giftId = { [isMembership ? 'planId' : 'packageId']: giftItem.id };

    setIsLocked(true);

    return axiosService
      .post(`${process.env.API_URL}/users/me/gift-cards/${giftType}`, extend(data, giftId))
      .then(async (res) => {
        if (has(res.data, 'data') && has(res.data.data, 'sessionId')) {
          const stripe = await stripePromise;

          return stripe.redirectToCheckout({ sessionId: res.data.data.sessionId })
        }
      })
      .finally(() => setIsLocked(false));
  }

  return (
    <Form
      className="gift-tab row d-flex justify-content-between pt-3 mt-3 pt-md-5 mt-md-5"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      {isLocked && (<Loader hasBackground={true} isFixed={true}/>)}
      <div className="col-12 col-md-5">
        <SendGiftForm
          isPreviewMode={isPreviewMode}
          giftItemsList={giftItems}
          type={type}
          isDisabled={isLocked}
          giftId={giftId}
          data={data}
          onGiftChange={onGiftChange}
          onChange={updateData}
        />
      </div>

      <div className="col-12 col-md-6 mb-1 mt-3 mt-md-0">
        <GiftPreview item={giftItem} activeTab={activeTab} />
        <GiftDetails giftPreviewData={data} />
      </div>
      <div className="col-12 text-center">
        <button type="submit" className="mt-4 btn-tg btn-tg-sale open-checkout-btn">Go to Checkout</button>
      </div>
    </Form>
  );
};

SendGiftTab.propTypes = {
  giftItems: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isReady: PropTypes.bool.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  giftId: PropTypes.number,
  data: PropTypes.object,
  onDataChange: PropTypes.func,
};

export default SendGiftTab;
