import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BluebirdPromise from 'bluebird';
import './SendGift.scss'
import hasWindow from '../../../constants/has-window.const';
import axiosService  from '../../../services/axios.service';
import { Tabs, Tab } from 'react-bootstrap';
import SendGiftTab from '../SendGiftTab/SendGiftTab';
import { planDescription } from '../../../constants/plan-descriptions.const';
import QueryParamsHelper from '../../../helpers/query-params.helper';
import { has, isArray, isEmpty, isString, isNumber, map, extend } from 'underscore';
import SuccessGiftModal from '../SuccessGiftModal/SuccessGiftModal';

export const SendGift = ({ isPreviewMode = false }) => {
  const [activeTab, setActiveTab] = useState('package');
  const [plans, setPlans] = useState([]);
  const [packages, setPackages] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [predefinedPlanId, setPredefinedPlanId] = useState(null);
  const [predefinedPackageId, setPredefinedPackageId] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [formData, setFormData] = useState({
    senderName: '',
    senderEmail: '',
    recipientName: '',
    recipientEmail: '',
    message: '',
    termsState: false,
  });

  if (hasWindow) {
    useEffect(() => {
      initQueryParams();
      loadData();
    }, []);
  }

  function loadData() {
    BluebirdPromise.all([
      getPackagesList(),
      getPlansList()
    ])
      .then(([packages, plans]) => {
        setPlans(plans);
        setPackages(packages);
      })
      .finally(() => {
        setIsReady(true);
      });
  }

  async function getPackagesList() {
    const params = { limit: 1000, projection: 'id,title' };
    return await axiosService
      .get(`${process.env.API_URL}/packages`, { params })
      .then((res) => res.data.data);
  }

  async function getPlansList() {
    return await axiosService
      .get(`${process.env.API_URL}/plans`)
      .then(res => res.data)
      .then(({ data }) => (data || []).filter(plan => plan.interval === 'year'))
      .then(plans => (plans || []).map(plan => {
        plan.description = planDescription[plan.code];
        return plan;
      }));
  }

  function initQueryParams() {
    if (isPreviewMode) {
      return;
    }

    const queryParams = pickQueryParams();
    applyQueryParams(queryParams);
  }

  function pickQueryParams() {
    let paramKeys = QueryParamsHelper
      .getAllUniqueKeys()
      .filter(key => ['type', 'id', 'email', 'session_id'].includes(key));

    if (!isArray(paramKeys) || isEmpty(paramKeys)) {
      return {};
    }

    const params = {};
    map(paramKeys, (paramKey) => {
      const param = QueryParamsHelper.getOne(paramKey);

      if (isString(param) && !isEmpty(param)) {
        params[paramKey] = param;
      }
    });

    return params;
  }

  function applyQueryParams(queryParams = {}) {
    const hasType = has(queryParams, 'type') && ['package', 'membership'].includes(queryParams.type);
    if (hasType) {
      setActiveTab(queryParams.type);
    }

    if (hasType && has(queryParams, 'id')) {
      setPredefinedGiftId(queryParams.type, queryParams.id);
    }

    if (has(queryParams, 'email') && !isEmpty(queryParams.email)) {
      const email = decodeURIComponent(queryParams.email);
      setFormData(extend(formData, {
        senderEmail: email
      }));
    }

    if (has(queryParams, 'session_id')) {
      setModalState(true);
    }
  }

  function setPredefinedGiftId(type, idParam) {
    const id = parseInt(idParam, 10);

    if (!isNumber(id)) {
      return;
    }

    switch (type) {
      case 'package': return setPredefinedPackageId(id);
      case 'membership': return setPredefinedPlanId(id);
    }
  }

  function changeData(data) {
    setFormData(data);
  }

  function closeConfirmModal() {
    QueryParamsHelper.removeQueryParamsByNames(['session_id'], false);
    setModalState(false);
  }

  return (
    <div className={`container send-gift pt-4 mb-10`}>
      <Tabs activeKey={activeTab} className="send-gift-tabs" onSelect={setActiveTab}>
        <Tab eventKey="package" title="Package">
          <SendGiftTab
            type="package"
            isPreviewMode={isPreviewMode}
            isReady={isReady}
            giftItems={packages}
            activeTab={activeTab}
            giftId={predefinedPackageId}
            data={formData}
            onDataChange={changeData}
          />
        </Tab>
        <Tab eventKey="membership" title="Membership">
          <SendGiftTab
            type="membership"
            isPreviewMode={isPreviewMode}
            isReady={isReady}
            giftItems={plans}
            activeTab={activeTab}
            giftId={predefinedPlanId}
            data={formData}
            onDataChange={changeData}
          />
        </Tab>
      </Tabs>
      <SuccessGiftModal
        isOpen={modalState}
        onConfirm={closeConfirmModal}
        onClose={closeConfirmModal}
      />
    </div>
  );
};

SendGift.propTypes = {
  isPreviewMode: PropTypes.bool,
};

export default SendGift;
