import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isObject, isEmpty } from 'underscore';
import './GiftPreview.scss';
import { formatPrice } from '../../../helpers/format-price.helper';

export const GiftPreview = ({ activeTab, item = null }) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (hasDetails(item)) {
      setDetails(item);
    } else {
      setDetails(null);
    }
  }, [activeTab, item]);

  function getLabel() {
    return isMembershipType() ? 'Membership Plan' : 'Package';
  }

  function isMembershipType() {
    return activeTab === 'membership';
  }

  function hasDetails(item) {
    return isObject(item) && !isEmpty(item);
  }

  function getFormattedPrice() {
    const price = isMembershipType() ? item.amount : item.price;

    return formatPrice(price);
  }

  return (
    <div className="gift-preview">
      { details && (
        <div>
          <p className="text-primary-600 small font-weight-bold text-uppercase mb-0">{ getLabel() }</p>
          <h3 className="title mt-1 mb-3 mb-md-4">{ item.title }</h3>

          <p className="price d-flex align-items-center mb-3 mb-md-4">
            $ {getFormattedPrice()}
            { activeTab === 'membership' && (<span className="interval text-muted ml-2">per<br />year</span>)}
          </p>

          <p className="payment-details mb-2 mb-md-5 font-weight-bold">
            <span>Total due today:</span>
            <span className="position-relative">
              ${getFormattedPrice()}
            </span>
          </p>

          <p className="multi-line-text-truncate line-4 mb-3 mt-3 mt-md-4 description">{ item.description }</p>
        </div>
      )}

      { !details && (
        <div className="no-content d-flex justify-content-center text-center p-4">
          <p className="font-size-base text-muted m-auto">Select { getLabel().toLocaleLowerCase() } on the left part, please</p>
        </div>
      )}
    </div>
  );
};

GiftPreview.propTypes = {
  activeTab: PropTypes.string.isRequired,
  item: PropTypes.object,
};

export default GiftPreview;
